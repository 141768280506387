import { Dispatch } from "@reduxjs/toolkit"
import { RefObject } from "react"
import { setBuffering, setPlaying, setError, setLoading } from "../redux/slices/playerSlice"
import { useSelector } from "react-redux";


export interface PlayContentProps {
    playerRef: RefObject<HTMLVideoElement | null>
    dispatch: Dispatch
    cb?: () => void
    errMsg?: string
    
}

const defaultErrMsg = 'Error, please click play again.'

export const playContent = ({ playerRef, dispatch, cb, errMsg }: PlayContentProps) => {
    const playPromise = playerRef?.current?.play();

    if (playPromise !== undefined ) {
        playPromise
            .then((_ => {
                dispatch(setLoading(false));
                cb?.()
            }))
            .catch(error => {
                dispatch(setBuffering(false))
                dispatch(setPlaying(false))
                dispatch(setLoading(false));
                dispatch(setError(errMsg ?? defaultErrMsg))
            });
    }

}
