import { Flex, Stack, Text } from "@chakra-ui/react";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    TelegramIcon,
  } from "react-share";
import { useGaSendEvent } from "../../hooks/GA";
  
const SocialShare = () => {
  const { sendEvent } = useGaSendEvent()

    const url = window.location.href
    const handleShared = () => {
      sendEvent({
        category: "share_event",
        action: "social_share",
        label: "Social Share",
        nonInteraction: false,
      });
    }
  return (
   <Stack align='center'>
    <Text mb={3} fontSize={18}>Share to your favourite social media</Text>
     <Flex gap={3}>
        <FacebookShareButton 
          url={url} 
          data-id = "share-facebook"
          title='Enjoy live streams with Atunwa Player'
          hashtag="#YourCustomHashtag"
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={42} round />
        </FacebookShareButton>

        <TwitterShareButton 
          onClick={handleShared}
          data-id = "share-twitter"
          url={url}
          title='Enjoy live streams with Atunwa Player'
          hashtags={["JesusSaves", "AtunwaLiveRadio"]}
          className="Demo__some-network__share-button"
          related={[
            '@sovtech', '@realDonaldTrump'
          ]}
        >
          <TwitterIcon size={42} round />
        </TwitterShareButton>

        <WhatsappShareButton 
          url={url}
          data-id = "share-wahtsapp"
          title='Enjoy live streams with Atunwa Player'
          className="Demo__some-network__share-button"
        >
          <WhatsappIcon size={42} round />
        </WhatsappShareButton>

        <LinkedinShareButton 
          url={url}
          data-id='share-linkedin'
          title='Atunwa Player'
          source='https://dev.atunwadigital.com/'
          summary='Enjoy live streams with Atunwa Player'
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon size={42} round />
        </LinkedinShareButton>

        <TelegramShareButton 
          url={url}
          data-id= "share-telegram"
          title='Enjoy live streams with Atunwa Player'
          className="Demo__some-network__share-button"
        >
          <TelegramIcon size={42} round />
        </TelegramShareButton>
    </Flex>
   </Stack>
  )
}

export default SocialShare