import {
  createContext,
  ReactNode,
  RefObject,
  useContext,
  useMemo,
} from "react";
import { ControlsOverlayProps, HlsPlayerProps } from "../../components/types";
import usePlayer from "../../hooks";
import useControls, { ControlsOverlayValues } from "../../hooks/useControls";
import { useListeningTime } from "../../hooks/GA";
import { DimensionsValues, useDimensions } from "../../hooks/useDimensions";
import { config } from "../../../config";

interface PlayerContextProviderProps extends HlsPlayerProps {
  children: ReactNode;
  adContainer: RefObject<HTMLDivElement>
}

interface PlayerContextValues extends ControlsOverlayProps, ControlsOverlayValues, DimensionsValues {}
const PlayerContext = createContext<PlayerContextValues | null>(null);

export const usePlayerContext = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error(
      "usePlayerContext must be used within PlayerContextProvider"
    );
  }
  return context;
};

const PlayerContextProvider: React.FC<PlayerContextProviderProps> = ({
  children,
  uiContainerRef,
  adContainer,
  audio,
}: PlayerContextProviderProps) => {

  const playerVals: ControlsOverlayProps = usePlayer({
    audio,
    uiContainerRef,
  });

  const controlVals: ControlsOverlayValues = useControls({ ...playerVals , adContainer });

  /* track listening time in a session and buffering events */
  useListeningTime({ audio });

  /* get player parent container dimension */
  const dimensions = useDimensions();

  const value: PlayerContextValues = useMemo(
    () => ({ ...playerVals, ...controlVals, ...dimensions  }),
    [playerVals, controlVals]
  );

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};

export default PlayerContextProvider;
