// Asynchronous loader for external scripts
import { store } from "../redux/store";
import { setAdblockerEnabled } from "../redux/slices/playerSlice";

const adSrc = [
  "https://imasdk.googleapis.com/js/sdkloader/ima3.js",
  "//c.amazon-adsystem.com/aax2/apstag.js",
  "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
];

const loadScript = (src: string): Promise<Event> =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = src;
    script.onload = resolve;
    script.onerror = () => {
      if (adSrc.includes(src)) {
        store.dispatch(setAdblockerEnabled(true));
      }
      return reject;
    };
    document.head.appendChild(script) ||
      document.getElementsByTagName("head")[0].appendChild(script);
  });

export default loadScript;
