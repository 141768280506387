export const formatTime = (time: number) => {
      // Format seconds
      const result = new Date(time * 1000)
        .toISOString()
        .slice(11, 19);

      let split = result.split(":");

      let obj = { sec: split[2], min: split[1], hour: split[0] };
      return obj;
    }