import { ApolloClient, InMemoryCache } from "@apollo/client";
import { config } from "../config";

let baseUrl;
const baseOrigin = window.location.origin
if (config.mode === 'DEV') {
  baseUrl = `${config.baseUrl}/graphql`
} else {
  if (baseOrigin.includes('atunwadigital.com')) {
    baseUrl = `${window.location.origin}/graphql`;
  } else {
   baseUrl = 'https://amp.atunwadigital.com/graphql'
  }
}
export const client = new ApolloClient({
  uri: baseUrl,
  cache: new InMemoryCache(),
});
