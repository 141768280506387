import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface AdsState {
  loading: boolean;
  gptLoading: boolean;
  playing: boolean;
  paused: boolean;
  volume: number;
  isFullscreen: boolean;
  metadata: any;
  error: string;
  url: string;
  contentType: string;
  data: { [key: string]: any };
  consent: boolean;
  prerollPlayed: boolean;
  adswizzConsent: boolean;
}

const initialState: AdsState = {
  loading: true,
  gptLoading: true,
  playing: false,
  paused: false,
  volume: 0.5,
  isFullscreen: false,
  metadata: { title: "", name: "" },
  error: "",
  url: "",
  contentType: "",
  data: {},
  consent: false,
  adswizzConsent: true,
  prerollPlayed: false,
};

const slice = createSlice({
  name: "ads",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setGptLoading: (state, action: PayloadAction<boolean>) => {
      state.gptLoading = action.payload;
    },
    setPlaying: (state, action: PayloadAction<boolean>) => {
      state.playing = action.payload;
    },
    setPaused: (state, action: PayloadAction<boolean>) => {
      state.paused = action.payload;
    },
    setVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
    setIsFullscreen: (state, action: PayloadAction<boolean>) => {
      state.isFullscreen = action.payload;
    },
    setMetadata: (state, action: PayloadAction<object>) => {
      state.metadata = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setContentType: (state, action: PayloadAction<string>) => {
      state.contentType = action.payload;
    },
    setData: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.data = action.payload;
    },
    setConsent: (state, action: PayloadAction<boolean>) => {
      state.consent = action.payload;
    },
    setAdswizzConsent: (state, action: PayloadAction<boolean>) => {
      state.adswizzConsent = action.payload;
    },
    setPrerollePlayed: (state, action: PayloadAction<boolean>) => {
      state.prerollPlayed = action.payload;
    },
  },
});

export const {
  setLoading,
  setGptLoading,
  setPaused,
  setPlaying,
  setMetadata,
  setVolume,
  setIsFullscreen,
  setError,
  setUrl,
  setContentType,
  setData,
  setConsent,
  setAdswizzConsent,
  setPrerollePlayed,
} = slice.actions;

export const selectLoading = (state: { ads: AdsState }) => state.ads.loading;

export const selectGptLoading = (state: { ads: AdsState }) =>
  state.ads.gptLoading;

export const selectPlaying = (state: { ads: AdsState }) => state.ads.playing;

export const selectPaused = (state: { ads: AdsState }) => state.ads.paused;

export const selectVolume = (state: { ads: AdsState }) => state.ads.volume;

export const selectIsFullscreen = (state: { ads: AdsState }) =>
  state.ads.isFullscreen;

export const selectMetadata = (state: { ads: AdsState }) => state.ads.metadata;

export const selectError = (state: { ads: AdsState }) => state.ads.error;

export const selectUrl = (state: { ads: AdsState }) => state.ads.url;
export const selectContentType = (state: { ads: AdsState }) =>
  state.ads.contentType;
export const selectData = (state: { ads: AdsState }) => state.ads.data;
export const selectConsent = (state: { ads: AdsState }) => state.ads.consent;
export const selectAdswizzConsent = (state: { ads: AdsState }) =>
  state.ads.adswizzConsent;
export const selectPrerollPlayed = (state: { ads: AdsState }) =>
  state.ads.prerollPlayed;

export default slice.reducer;
