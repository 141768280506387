import {useRef, useState} from "react";
import useGpt from "../hooks/Ads/GPT";
import {useInitIma} from "../hooks/Ads/IMA/useInitIma";
import {useDispatch, useSelector} from "react-redux";
import {setEnded, setLoading, setPlaying} from "../redux/slices/playerSlice";
import {playAds} from "../hooks/Ads/IMA/playAds";
import AtunwaAppComponent from "./AtunwaAppComponent";
import * as adsState from "../redux/slices/adsSlice";
import {useImaSDK} from "../hooks/Ads/IMA/useImaSDK";

function AtunwaApp() {
    const adContainer = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const [stream, setStream] = useState<boolean>(false);

    const {requestAd} = useImaSDK({setStream})


    const playContent = () => {
        dispatch(adsState.setPrerollePlayed(true));
        setStream(true);
        dispatch(adsState.setPlaying(false));
        dispatch(setPlaying(true))
    }

    const requestAds = () => {
        requestAd().finally(playContent)
    };

    return (
        <AtunwaAppComponent
            handlePlay={requestAds}
            stream={stream}
        />
    );
}

export default AtunwaApp;
