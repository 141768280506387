import { config } from "../../../../config";

function adsWizzCompanionOutOfContextFunction() {
  console.log("<<<<BANNER LISTENER>>>>> adsWizzCompanionOutOfContextFunction");
}

function adsWizzCompanionWillDisplayFunction(companionBannerURLString: string) {
  console.log("<<<<BANNER LISTENER>>>>> adsWizzCompanionWillDisplayFunction srcURL = " + companionBannerURLString);
}

function adsWizzCompanionWillDisplayFallbackFunction(fallbackBannerULRString: string) {
  console.log("<<<<BANNER LISTENER>>>>> adsWizzCompanionWillDisplayFallbackFunction srcURL = " + fallbackBannerULRString);
}

const initAdwizz = () => {
  /* @ts-expect-error */
  if (window.adswizzSDK) {
    /* @ts-expect-error */
    if (window.adswizzSDK) {
      /* @ts-expect-error */
      window.adswizzSDK.init({
        listenerConsent: false,
        contentPlayer: document.getElementById(config.contentElementId),
        playerId: "atunwa_web",
        companionBanner: {
          container: document.getElementById(config.companionElementId),
          size: [300, 250],
          baseURL: "https://atunwa.deliveryengine.adswizz.com/www/delivery/afr.php",
          zoneId: "2459",
          fallbackZoneId: "2461",
          alwaysDisplayAds: true,
          extraExposureTime: 5000,
          outOfContextListener : adsWizzCompanionOutOfContextFunction,
          willDisplayListener : adsWizzCompanionWillDisplayFunction,
          willDisplayFallbackListener : adsWizzCompanionWillDisplayFallbackFunction
        },
      });
    }
  }
};

export default initAdwizz;
