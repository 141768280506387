import { useEffect } from "react";
import { useGaInit, useGaSendEvent } from ".";
import { ControlsOverlayProps } from "../../components/types";

  /* tracks listening time in a session */

const useListeningTime = ({ audio }: ControlsOverlayProps) => {
  const { gaInit } = useGaInit();
  const { sendEvent } = useGaSendEvent();
  const showConfirmation = false;

  const handleUnload = (event: BeforeUnloadEvent) => {
    if (audio?.current) {
      if (audio.current?.currentTime > 0) {
        sendEvent({
          category: "listening_time_event",
          action: "listening_time",
          label: "Listening Time",
          value: audio.current?.currentTime,
          nonInteraction: false,
        });
      }
    }

    // to show a confirmation dialog before leaving/refreshing the page:
    if (showConfirmation) {
      event.preventDefault();
      event.returnValue = "This will interupt your streaming experience and any unsaved data may be lost";
    }
  };

  useEffect(() => {
    gaInit(); //initialize GA app-wide

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);
};

export default useListeningTime;
