import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading, setPlaying } from "../redux/slices/playerSlice";
import * as adsState from "../redux/slices/adsSlice";
import AtunwaAppComponent from "./AtunwaAppComponent";

function AtunwaAppAdblocker() {
  const dispatch = useDispatch();
  const [stream, setStream] = useState<boolean>(false);

  const handlePlay = () => {
    setStream(true);
    dispatch(adsState.setPlaying(false));
    dispatch(setPlaying(true));
  };

  const handleAdblocker = () => {
    dispatch(adsState.setLoading(false));
    dispatch(setLoading(false));
    dispatch(adsState.setPrerollePlayed(true));
  };

  return (
    <AtunwaAppComponent
      handlePlay={handlePlay}
      handleAdblocker={handleAdblocker}
      stream={stream}
    />
  );
}

export default AtunwaAppAdblocker;
