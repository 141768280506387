import { useCallback } from "react";
import ReactGA from "react-ga4";
import { SendEventProps } from "../../components/types";

const useGASendEvent = () => {
  const sendEvent = useCallback(
    ({ category, action, label, nonInteraction, value }: SendEventProps) => {
      ReactGA.event({ category, action, label, value, nonInteraction });
    },
    []
  );

  return { sendEvent };
};
export default useGASendEvent;
