import { useEffect, useState } from "react"
import { getCookieConsentValue } from "react-cookie-consent";

export interface DimensionsValues {
  controlsH: number;
  liveBadgeH: number;
  bannerH: number;
  width: number;
  height: number;
  minH: number;
  minW: number;
}

export const useDimensions = () => {
  const getParentContainer = () => document.getElementById('atunwa-player')?.getBoundingClientRect();
  const consent = getCookieConsentValue();

  const controlsH = 65;
  const liveBadgeH = 55;
  const bannerH = consent === undefined ? 600 : 400;
    
  const minW: number = 320; //minimum mobile device, (iphone 5)
  const minH: number = bannerH + controlsH;
  
  const [w, setW] = useState<number>(minW);
  const [h, setH] = useState<number>(minH);
  
 
  const handleResize = () => {
    const newParentContainer = getParentContainer();
    if (newParentContainer) {
      const parentW = newParentContainer.width;
      const parentH = newParentContainer.height;
      
      const adjustW =
        parentW >= minW && parentW <= window.innerWidth
          ? parentW
          : minW < window.innerWidth
          ? minW
          : window.innerWidth;
      const adjustH =
        parentH >= minH && parentH <= window.innerHeight
          ? parentH
          : minH < window.innerHeight
          ? minH
          : window.innerHeight;  
      setW(adjustW);
      setH(adjustH);
    }
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call on load
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return {
    controlsH,
    liveBadgeH,
    bannerH,
    width: w,
    height: h,
    minH,
    minW,
  } as DimensionsValues;
};