import { useEffect } from 'react';
import { useGaSendEvent } from '.';

 const useageLoadMetrics = () => {
  const { sendEvent } = useGaSendEvent();

  useEffect(() => {
    const perfObserver = new PerformanceObserver((observedEntries) => {
      const entry: PerformanceEntry =
        observedEntries.getEntriesByType('navigation')[0];
        if (entry.duration > 0) {
            sendEvent({
              category: "load_time_event",
              action: "load_time",
              label: "Page Load Time",
              value: entry.duration,
              nonInteraction: true,
            });
          }

    });

    perfObserver.observe({
      type: 'navigation',
      buffered: true,
    });

    return () => {
      perfObserver.disconnect();

    }
  }, []);
};

export default useageLoadMetrics;