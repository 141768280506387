import { Flex, Stack, Link, Box, Text } from "@chakra-ui/react";
import CookieIcon from "../../../assets/icons/CookieIcon";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../redux/slices/playerSlice";

const UnIntrusive = ({ handleOpen }: { handleOpen: () => void }) => {
  const publisherConfig = useSelector(selectConfig);

  return (
    <Flex gap={3}>
      <Box>
        <CookieIcon />
      </Box>
      <Stack>
        <Text color='gray.800' fontSize="lg" fontWeight="bold">
          Cookies
        </Text>
        <Text color='gray.500'>
          By using this website, you consent to the use of cookies.
        </Text>
      </Stack>
    </Flex>
  );
};

export default UnIntrusive;