import { SimpleGrid, VStack, Flex } from "@chakra-ui/react";
import LiveBadge from "../LiveBadge";
import PlayButton from "../PlayButton";
import StreamMeta from "../StreamMeta";
import Controls from "./Controls";
import { usePlayerContext } from "../../../providers/PlayerProvider.tsx";
import { config } from "../../../../config";

export const ControlsOverlay = () => {
  const { controlsH } = usePlayerContext();

  return (
    <SimpleGrid
      columns={3}
      spacing={10}
      id={config.controlsId}
      alignItems="center"
      h={controlsH}
    >
      <VStack h="55px" align="flex-start">
        <LiveBadge />
        {/*<StreamMeta />*/}
      </VStack>
      <Flex flexBasis="100%" justifyContent="center">
        <PlayButton />
      </Flex>
      <Controls />
    </SimpleGrid>
  );
};
