import React from "react";
import { AdProps } from "../types";
import useGpt from "../../hooks/Ads/GPT";
import { useDispatch, useSelector } from "react-redux";
import { selectConsent, setConsent } from "../../redux/slices/adsSlice";
import { Box } from "@chakra-ui/react";
import { usePlayerContext } from "../../providers/PlayerProvider.tsx";
import { getCookieConsentValue } from "react-cookie-consent";
import { selectConfig } from "../../redux/slices/playerSlice";
import { selectLoading } from "../../redux/slices/playerSlice";

let adCounter = 0;

const GptAd = ({ path }: AdProps) => {
  const GPTAdsManager = useGpt();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const consent = useSelector(selectConsent);
  const publisherConfig = useSelector(selectConfig);

  const slotConfig = {
    domain: publisherConfig.domain,
    publisherName: publisherConfig.publisher.name,
    playerName: publisherConfig.playerName
  }
  const id = React.useMemo(() => `div-gpt-ad-${++adCounter}`, []);

  const { width, height } = usePlayerContext();

  React.useEffect(() => {

    if (GPTAdsManager && !loading ) {
      const slot = GPTAdsManager.createSlot(path, id, width, height, slotConfig);

      if (!consent) {
        dispatch(setConsent(getCookieConsentValue() === "true"));
      }
      slot.display();
      return () => {
        slot.destroy();
      };
    }
  }, [path, consent, width, height, loading]);

  return <Box id={id} />;
};

export default GptAd;
