let initialized = false;

const InitializeAds = (
  initialLoading = false,
  singleRequest = true,
  pubads: googletag.PubAdsService
) => {
  if (initialized) {
    return;
  }
  initialized = true;
  window.googletag.cmd.push(() => {
    if (!initialLoading && pubads) {
      pubads.disableInitialLoad();
    }
    if (singleRequest && pubads) {
      pubads.enableSingleRequest();
    }
    window.googletag.enableServices();
  });
};

export default InitializeAds;