import { Tooltip, Text } from "@chakra-ui/react";
import { Dispatch } from "@reduxjs/toolkit";
import { setMetadata } from "../redux/slices/playerSlice";

const formatText = (str?: string) => str?.replace(/[title="']/g, "");
export default formatText;

export const truncateString = (str: string, maxLength: number) =>
  str && str?.length <= maxLength ? str : `${str?.substring(0, maxLength)}...`;

export interface TooltipProps {
  text: string;
  maxLength: number;
}
export const TooltipText = ({ text, maxLength }: TooltipProps) => (
  <Tooltip label={text}>
    <Text>{text && truncateString(text, maxLength)}</Text>
  </Tooltip>
);

export const get_url_extension = (url: string) => {
  return url.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
};

export interface TextMetadataProps {
  event: any;
  metadata: { title: string; name: string };
  dispatch: Dispatch;
}

export const handleTextMetadata = ({
  event,
  metadata,
  dispatch,
}: TextMetadataProps) => {
  const data = event?.payload?.data?.split("-");
  const dataTitle = formatText(data?.[1]);
  const dataName = formatText(data?.[0]);
  const { title, name } = metadata;
  if (title !== dataTitle || name !== dataName) {
    dispatch(setMetadata({ title: dataTitle, name: dataName }));
  }
};
