import { useEffect } from "react";
import {
  RxSpeakerQuiet,
  RxSpeakerModerate,
  RxSpeakerLoud,
} from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { ControlsOverlayProps } from "../components/types";
import {
  selectVolume,
  setError,
  setIsFullscreen,
  setVolume,
} from "../redux/slices/playerSlice";
import { useGaSendEvent } from "./GA";

export interface ControlsOverlayValues {
  speakerIcon: JSX.Element
  pip: () => Promise<void>
  fullScreen: () => Promise<void>
  mute: () => void
}

const useControls = ({ uiContainerRef, audio }: ControlsOverlayProps) => {
  const dispatch = useDispatch();
  const { sendEvent } = useGaSendEvent();

  const volume = useSelector(selectVolume);

  const mute = () => {
    if (audio?.current) {
      if (!audio?.current.muted) {
        audio.current.muted = true;
        sendEvent({
          category: "mute_event",
          action: "mute",
          label: "Mute Stream",
          nonInteraction: false,
        });
        dispatch(setVolume(0));
      } else {
        audio.current.muted = false;
        sendEvent({
          category: "unmute_event",
          action: "unmute",
          label: "Unmute Stream",
          nonInteraction: false,
        });
        dispatch(setVolume(0.5));
      }
    }
  };

  const handleFullscreenChange = () => {
    if (
      !document.fullscreenElement &&
      //@ts-ignore
      !document.webkitFullscreenElement &&
      //@ts-ignore
      !document.mozFullScreenElement &&
      //@ts-ignore
      !document.msFullscreenElement
    ) {
      exitHandleFullscreen();
    }
  };

  // Add the event listener when the component mounts
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  });

  const exitHandleFullscreen = async () => {
    if (uiContainerRef?.current) {
      document.exitFullscreen();
      dispatch(setIsFullscreen(false));
    }
  };

  const fullScreen = async () => {
    const fullscreenOn = document.fullscreenElement;

    if (uiContainerRef?.current) {
      if (fullscreenOn) {
        exitHandleFullscreen();
      } else {
        await uiContainerRef.current.requestFullscreen();
        dispatch(setIsFullscreen(true));
        sendEvent({
          category: "exit_fullscreen_event",
          action: "exit_fullscreen",
          label: "Exit Fullscreen",
          nonInteraction: false,
        });
      }
    }
  };

  const pip = async () => {
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
      } else if (audio?.current && audio.current instanceof HTMLVideoElement) {
        await audio.current.requestPictureInPicture();
      }
    } catch (error) {
      console.log("pip");
      dispatch(setError("Failed to toggle PiP mode"));
    }
  };

  const lowSpeakerIcon =
    volume && volume < 0.4 ? (
      <RxSpeakerQuiet size={24} />
    ) : (
      <RxSpeakerModerate size={24} />
    );

  const speakerIcon =
    volume && volume < 0.9 ? lowSpeakerIcon : <RxSpeakerLoud size={24} />;
  return {
    speakerIcon,
    pip,
    fullScreen,
    mute,
  } as ControlsOverlayValues;
};

export default useControls;
