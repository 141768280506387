/**
 * https://chakra-ui.com/docs/styled-system/theming/theme
 *
 * Please read through the Default Theme documentation for a clear understanding before extending it,
 * Chakra theming is a powerful tool 🛠
 */

import {
  extendTheme,
  type ThemeConfig,
} from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
};
const borders = { default: "1px solid white" };

const colors = {
  primary: {
    main: "#6A5B97",
    white: "#fff",
    error: "var(--chakra-colors-red-300)",
    success: "var(--chakra-colors-green-300)",
  },
  gradient: {
    purple: "linear-gradient(117deg, #FFCBBA 0%, #94549D 45.83%, #091A3C 88.02%)",
  },
  secondary: {
    main: "#001540",
    dark: "#333",

  },
  error: "#F04438",
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const components = {
 
  Modal: {
    baseStyle: {
      overlay: {
        backdropFilter: "blur(8px)", 
        backgroundColor: "rgba(0, 0, 0, 0.3)", 
      },
    },
  },

  Button: {
    variants: {
      iconButton: {
        backgroundColor: "transparent",
        fontSize: "20px",
        color: "white",
        borderRadius: "8px",

        // _hover: { backgroundColor: "rgb(250, 250, 250, 0.1)" },  
        _focus: { outline: "none" },
      },
    },
  },

};

const textStyles = {
  caption: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  p: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  h1: {
    fontSize: ["48px", "72px"],
    fontWeight: 900,
    lineHeight: 1,
  },
  h2: {
    fontSize: ["20px", "20px"],
    fontWeight: 400,
    lineHeight: 1,
    color: "#ffffff",
  },
  h3: {
    fontSize: ["36px", "36px"],
    fontWeight: 400,
    lineHeight: 1.2,
    color: "#001540",
  },
  h4: {
    fontSize: ["14px", "14px"],
    lineHeight: ["20px", "24px"],
    color: "#ff7575",
  },
  h5: {
    fontSize: ["16px", "18px"],
    fontWeight: "semibold",
    lineHeight: 1.2,
  },
  body: {
    fontSize: ["18px", "27px"],
    color: "#ffffff",
  },
 
};



const theme = extendTheme({
  colors,
  config,
  components,
  textStyles,
  borders,
  fonts: {
    heading: `'League Spartan', sans-serif`,
    body: `'League Spartan', sans-serif`,
  }
});

export default theme;
