import { useCallback } from "react";
import ReactGA from "react-ga4";
import { config } from "../../../config";

const useGAInit = () => {
  const gaInit = useCallback(() => {
    ReactGA.initialize([
      {
        trackingId: config.analyticMeasurementId,
      },
    ]);
  }, []);

  return { gaInit };
};

export default useGAInit;
