import { config } from "../../../config";

const PoweredByLogo = () => {
  const { controlsH } = config;
  return (
    <svg
      width={"75%"}
      height={"75%"}
      viewBox="0 0 149 41"
      fill="none"
      opacity={0.4}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.1664 28.3565L73.9744 25.5863H68.6023L67.4103 28.3565H64.3657L69.9029 15.5327H72.6715L78.2087 28.3565H75.1664ZM71.2963 19.3486L69.6835 23.0717H72.8909L71.2963 19.3486Z"
        fill="white"
      />
      <path
        d="M84.1871 18.0088V28.3565H81.3257V18.0088H77.6953V15.5327H87.8152V18.0088H84.1848H84.1871Z"
        fill="white"
      />
      <path
        d="M93.2981 25.0431C93.7753 25.6361 94.4155 25.9326 95.223 25.9326C96.0305 25.9326 96.6684 25.6361 97.1388 25.0431C97.6093 24.4501 97.8446 23.6399 97.8446 22.6123V15.5305H100.706V22.7029C100.706 24.561 100.192 25.9892 99.1655 26.9873C98.1386 27.9831 96.8244 28.4833 95.223 28.4833C93.6215 28.4833 92.3051 27.9831 91.2714 26.9782C90.2377 25.9756 89.7219 24.5497 89.7219 22.7029V15.5305H92.581V22.6123C92.581 23.6399 92.8185 24.4501 93.2958 25.0431H93.2981Z"
        fill="white"
      />
      <path
        d="M112.642 15.5327H115.501V28.3565H112.642L106.537 20.3218V28.3565H103.676V15.5327H106.352L112.642 23.7892V15.5327Z"
        fill="white"
      />
      <path
        d="M122.654 22.9065L124.927 15.5305H127.915L130.17 22.9065L132.719 15.5305H135.818L131.344 28.3543H129.218L126.413 19.4392L123.626 28.3543H121.5L117.026 15.5305H120.125L122.656 22.9065H122.654Z"
        fill="white"
      />
      <path
        d="M145.793 28.3565L144.601 25.5863H139.229L138.037 28.3565H134.992L140.53 15.5327H143.298L148.835 28.3565H145.793ZM141.923 19.3486L140.31 23.0717H143.518L141.923 19.3486Z"
        fill="white"
      />
      <path
        d="M15.112 40.0057H0V24.8846L20.8641 4.008H35.9761V19.1291L15.112 40.0057Z"
        fill="white"
      />
      <path d="M56.8402 19.1314H35.9761V40.008H56.8402V19.1314Z" fill="white" />
      <path
        d="M64.3116 0.448H67.3036C67.9649 0.448 68.5596 0.557333 69.0876 0.776C69.6209 0.989333 70.0449 1.32533 70.3596 1.784C70.6742 2.24267 70.8316 2.84 70.8316 3.576C70.8316 4.30667 70.6742 4.912 70.3596 5.392C70.0449 5.872 69.6209 6.232 69.0876 6.472C68.5596 6.70667 67.9649 6.824 67.3036 6.824H65.6796V11.008H64.3116V0.448ZM65.6796 5.68H66.9276C67.3809 5.68 67.7916 5.61333 68.1596 5.48C68.5329 5.34667 68.8289 5.128 69.0476 4.824C69.2716 4.52 69.3836 4.112 69.3836 3.6C69.3836 3.08267 69.2716 2.68 69.0476 2.392C68.8289 2.09867 68.5329 1.89333 68.1596 1.776C67.7916 1.65333 67.3809 1.592 66.9276 1.592H65.6796V5.68Z"
        fill="white"
      />
      <path
        d="M74.9191 11.2C74.2417 11.2 73.6391 11.048 73.1111 10.744C72.5884 10.44 72.1777 10.0293 71.8791 9.512C71.5804 8.98933 71.4311 8.4 71.4311 7.744C71.4311 7.088 71.5804 6.496 71.8791 5.968C72.1777 5.44 72.5884 5.02133 73.1111 4.712C73.6391 4.40267 74.2417 4.248 74.9191 4.248C75.5911 4.248 76.1857 4.40267 76.7031 4.712C77.2257 5.02133 77.6337 5.44 77.9271 5.968C78.2204 6.496 78.3671 7.088 78.3671 7.744C78.3671 8.4 78.2204 8.98933 77.9271 9.512C77.6337 10.0293 77.2257 10.44 76.7031 10.744C76.1857 11.048 75.5911 11.2 74.9191 11.2ZM74.9191 10.008C75.3457 10.008 75.7164 9.90933 76.0311 9.712C76.3511 9.51467 76.5991 9.24533 76.7751 8.904C76.9511 8.55733 77.0391 8.168 77.0391 7.736C77.0391 7.304 76.9511 6.91467 76.7751 6.568C76.5991 6.22133 76.3511 5.94667 76.0311 5.744C75.7164 5.54133 75.3457 5.44 74.9191 5.44C74.4871 5.44 74.1111 5.54133 73.7911 5.744C73.4711 5.94667 73.2204 6.22133 73.0391 6.568C72.8631 6.91467 72.7751 7.304 72.7751 7.736C72.7751 8.168 72.8631 8.55733 73.0391 8.904C73.2204 9.24533 73.4711 9.51467 73.7911 9.712C74.1111 9.90933 74.4871 10.008 74.9191 10.008Z"
        fill="white"
      />
      <path
        d="M84.2383 6.088L82.5663 11.008H81.1263L79.3423 4.44H80.8223L82.0143 9.488L83.6623 4.568H84.8143L86.4463 9.488L87.6463 4.44H89.1343L87.3503 11.008H85.9103L84.2383 6.088Z"
        fill="white"
      />
      <path
        d="M91.3761 8.008C91.3814 8.40267 91.4667 8.76267 91.6321 9.088C91.7974 9.41333 92.0427 9.672 92.3681 9.864C92.6987 10.056 93.1041 10.152 93.5841 10.152C93.9894 10.152 94.3334 10.0933 94.6161 9.976C94.9041 9.85333 95.1387 9.70933 95.3201 9.544C95.5014 9.37333 95.6321 9.22133 95.7121 9.088L96.3921 9.928C96.2161 10.184 96.0001 10.408 95.7441 10.6C95.4934 10.792 95.1867 10.9387 94.8241 11.04C94.4614 11.1467 94.0187 11.2 93.4961 11.2C92.7921 11.2 92.1814 11.0507 91.6641 10.752C91.1521 10.448 90.7574 10.0293 90.4801 9.496C90.2027 8.95733 90.0641 8.34133 90.0641 7.648C90.0641 7.008 90.1947 6.432 90.4561 5.92C90.7174 5.40267 91.0961 4.99467 91.5921 4.696C92.0934 4.39733 92.6961 4.248 93.4001 4.248C94.0454 4.248 94.6054 4.384 95.0801 4.656C95.5601 4.92267 95.9307 5.30133 96.1921 5.792C96.4587 6.28267 96.5921 6.86933 96.5921 7.552C96.5921 7.59467 96.5894 7.672 96.5841 7.784C96.5787 7.89067 96.5734 7.96533 96.5681 8.008H91.3761ZM95.2641 6.96C95.2587 6.74133 95.1947 6.50133 95.0721 6.24C94.9547 5.97867 94.7627 5.75467 94.4961 5.568C94.2294 5.38133 93.8694 5.288 93.4161 5.288C92.9467 5.288 92.5681 5.37867 92.2801 5.56C91.9974 5.74133 91.7894 5.96267 91.6561 6.224C91.5227 6.48 91.4454 6.72533 91.4241 6.96H95.2641Z"
        fill="white"
      />
      <path
        d="M99.4971 11.008H98.1131V4.44H99.4971V5.576H99.4571C99.4837 5.43733 99.5824 5.264 99.7531 5.056C99.9237 4.848 100.158 4.66133 100.457 4.496C100.756 4.33067 101.113 4.248 101.529 4.248C101.833 4.248 102.1 4.29067 102.329 4.376C102.564 4.456 102.729 4.536 102.825 4.616L102.257 5.768C102.182 5.69333 102.052 5.61867 101.865 5.544C101.678 5.464 101.446 5.424 101.169 5.424C100.817 5.424 100.516 5.53333 100.265 5.752C100.014 5.97067 99.8224 6.23467 99.6891 6.544C99.5611 6.848 99.4971 7.13067 99.4971 7.392V11.008Z"
        fill="white"
      />
      <path
        d="M104.454 8.008C104.46 8.40267 104.545 8.76267 104.71 9.088C104.876 9.41333 105.121 9.672 105.446 9.864C105.777 10.056 106.182 10.152 106.662 10.152C107.068 10.152 107.412 10.0933 107.694 9.976C107.982 9.85333 108.217 9.70933 108.398 9.544C108.58 9.37333 108.71 9.22133 108.79 9.088L109.47 9.928C109.294 10.184 109.078 10.408 108.822 10.6C108.572 10.792 108.265 10.9387 107.902 11.04C107.54 11.1467 107.097 11.2 106.574 11.2C105.87 11.2 105.26 11.0507 104.742 10.752C104.23 10.448 103.836 10.0293 103.558 9.496C103.281 8.95733 103.142 8.34133 103.142 7.648C103.142 7.008 103.273 6.432 103.534 5.92C103.796 5.40267 104.174 4.99467 104.67 4.696C105.172 4.39733 105.774 4.248 106.478 4.248C107.124 4.248 107.684 4.384 108.158 4.656C108.638 4.92267 109.009 5.30133 109.27 5.792C109.537 6.28267 109.67 6.86933 109.67 7.552C109.67 7.59467 109.668 7.672 109.662 7.784C109.657 7.89067 109.652 7.96533 109.646 8.008H104.454ZM108.342 6.96C108.337 6.74133 108.273 6.50133 108.15 6.24C108.033 5.97867 107.841 5.75467 107.574 5.568C107.308 5.38133 106.948 5.288 106.494 5.288C106.025 5.288 105.646 5.37867 105.358 5.56C105.076 5.74133 104.868 5.96267 104.734 6.224C104.601 6.48 104.524 6.72533 104.502 6.96H108.342Z"
        fill="white"
      />
      <path
        d="M114.103 11.2C113.501 11.2 112.959 11.056 112.479 10.768C112.005 10.48 111.629 10.0773 111.351 9.56C111.079 9.03733 110.943 8.42667 110.943 7.728C110.943 7.02933 111.079 6.42133 111.351 5.904C111.629 5.38133 112.005 4.976 112.479 4.688C112.959 4.39467 113.501 4.248 114.103 4.248C114.589 4.248 115.015 4.33333 115.383 4.504C115.751 4.66933 116.045 4.864 116.263 5.088C116.482 5.312 116.607 5.50667 116.639 5.672V0H118.039V11.008H116.663V9.728C116.605 9.90933 116.458 10.1147 116.223 10.344C115.994 10.5733 115.698 10.7733 115.335 10.944C114.973 11.1147 114.562 11.2 114.103 11.2ZM114.423 10.048C114.829 10.048 115.199 9.952 115.535 9.76C115.871 9.568 116.138 9.29867 116.335 8.952C116.538 8.6 116.639 8.192 116.639 7.728C116.639 7.264 116.538 6.85867 116.335 6.512C116.138 6.16 115.871 5.888 115.535 5.696C115.199 5.49867 114.829 5.4 114.423 5.4C114.05 5.4 113.703 5.496 113.383 5.688C113.063 5.88 112.805 6.14933 112.607 6.496C112.415 6.84267 112.319 7.25333 112.319 7.728C112.319 8.20267 112.415 8.61333 112.607 8.96C112.805 9.30667 113.063 9.576 113.383 9.768C113.703 9.95467 114.05 10.048 114.423 10.048Z"
        fill="white"
      />
      <path
        d="M123.622 0.448H126.558C127.134 0.448 127.676 0.525333 128.182 0.68C128.689 0.834667 129.1 1.096 129.414 1.464C129.729 1.832 129.886 2.344 129.886 3C129.886 3.44267 129.798 3.84533 129.622 4.208C129.452 4.56533 129.238 4.856 128.982 5.08C128.726 5.304 128.473 5.43733 128.222 5.48C128.425 5.496 128.649 5.56 128.894 5.672C129.145 5.784 129.382 5.944 129.606 6.152C129.836 6.36 130.022 6.62133 130.166 6.936C130.316 7.24533 130.39 7.61333 130.39 8.04C130.39 8.616 130.273 9.128 130.038 9.576C129.804 10.024 129.446 10.376 128.966 10.632C128.492 10.8827 127.889 11.008 127.158 11.008H123.622V0.448ZM124.982 9.864H127.022C127.529 9.864 127.921 9.776 128.198 9.6C128.481 9.41867 128.678 9.184 128.79 8.896C128.908 8.608 128.966 8.30133 128.966 7.976C128.966 7.43733 128.79 7.00267 128.438 6.672C128.086 6.34133 127.577 6.176 126.91 6.176H124.982V9.864ZM124.982 5.056H126.75C127.156 5.056 127.489 4.968 127.75 4.792C128.012 4.61067 128.206 4.38133 128.334 4.104C128.462 3.82133 128.526 3.53067 128.526 3.232C128.526 2.61867 128.345 2.192 127.982 1.952C127.625 1.712 127.145 1.592 126.542 1.592H124.982V5.056Z"
        fill="white"
      />
      <path
        d="M134.826 9.736L137.042 4.44H138.434L134.002 14.336H132.594L134.082 11.008L131.378 4.44H132.794L134.826 9.736Z"
        fill="white"
      />
    </svg>
  );
};

export default PoweredByLogo;
