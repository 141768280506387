import { DisplaySlot, RefreshSlot, DestroySlot } from ".";
import AmazonPS from "./AmazonPS";

interface PublisherConfig {
    domain: string;
    publisherName: string;
    playerName: string;
}

const CreateSlot = (
  adPath: string,
  elementId: string | undefined,
  useAmazon: boolean,
  consentValue: boolean,
  pubads: googletag.PubAdsService,
  width: number,
  height: number,
  publisherConfig: PublisherConfig
): { display: () => void; refresh: () => void; destroy: () => void } => {

  let slot: googletag.Slot | null = null;

  googletag.cmd.push(() => {
    // Define the base size mapping
    const baseSizeMapping = googletag
        .sizeMapping()
        .addSize([0, 0], [300, 250])
        .build();

    // Initialize mapping with the base size mapping
    let mapping: googletag.SizeMappingArray | null = baseSizeMapping;

    if (width) {
      // Create a mapping based on the parent container's width
      if (width >= 728) {
        mapping = googletag
            .sizeMapping()
            .addSize([728, 90], [[728, 90], [468, 60], "fluid"])
            .addSize([468, 60], [[468, 60], "fluid"])
            .addSize(
                [336, 280],
                [[336, 280], [300, 250], [320, 100], [320, 50], "fluid"]
            )
            .addSize([300, 250], [[300, 250], "fluid"])
            .build();
      } else if (width >= 468) {
        mapping = googletag
            .sizeMapping()
            .addSize([468, 60], [[468, 60], "fluid"])
            .addSize(
                [336, 280],
                [[336, 280], [300, 250], [320, 100], [320, 50], "fluid"]
            )
            .build();
      } else if (width >= 336) {
        mapping = googletag
            .sizeMapping()
            .addSize(
                [336, 280],
                [[336, 280], [300, 250], [320, 100], [320, 50], "fluid"]
            )
            .addSize([300, 250], [[300, 250], "fluid"])
            .build();
      } else if (width >= 300) {
        mapping = googletag
            .sizeMapping()
            .addSize([300, 250], [[300, 250], "fluid"])
            .build();
      }
    }

    // if both adPath and elementId are truthy(not null, undefined, or an empty string), define the slot
    if (!!adPath && !!elementId) {
      const tmp = googletag.defineSlot(
          adPath,
          [[300, 250], [728, 90], [468, 60]],
          elementId
      );

      if (mapping && tmp) {
        tmp.defineSizeMapping(mapping);
      }

      if (tmp) {
        slot = tmp;
        tmp.setTargeting("lrp_domain", publisherConfig.domain);
        tmp.setTargeting("lrp_publisher_name", publisherConfig.publisherName);
        tmp.setTargeting("lrp_play_name", publisherConfig.playerName);

        tmp.addService(pubads);
      }
    }

    if (useAmazon && window.apstag && slot && consentValue) {
      AmazonPS(adPath, width, height, elementId, slot);
    }

  });

    const display = () => DisplaySlot({ slot, pubads });
    const refresh = () => RefreshSlot({ slot, pubads });
    const destroy = () => DestroySlot({ slot, pubads });

    return { display, refresh, destroy };

};

export default CreateSlot;
