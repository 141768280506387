import reactToWebComponent from "@r2wc/react-to-web-component";
import AtunwaPlayer from "./lib/AtunwaPlayer";
import React from "react";
import * as ReactDOMClient from "react-dom/client";

const root = document.getElementById("atunwa-player");
if (root) {
  ReactDOMClient.createRoot(root!).render(<AtunwaPlayer />);
} else {
customElements.define(
  "atunwa-player",
  // @ts-ignore
  reactToWebComponent(AtunwaPlayer, React, ReactDOMClient)
);
}
