import { AdsProps } from "../../../../components/types";

const DisplaySlot = ({ slot, pubads }: AdsProps) => {
  if (slot && pubads) {
    googletag.cmd.push(() => {
      pubads.refresh([slot]);
    });
  }
  return;
};
export default DisplaySlot;