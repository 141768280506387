import { Button } from "@chakra-ui/react";
import React from "react";
import CookieConsent, {
  getCookieConsentValue,
} from "react-cookie-consent";
import { useDispatch, useSelector } from "react-redux";
import { setAdswizzConsent, setConsent } from "../../../redux/slices/adsSlice";
import UnIntrusive from "./UnIntrusive";
import { selectConfig } from "../../../redux/slices/playerSlice";
import lightOrDark from "../../../util/lightOrDark";


const CookieForm = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch();


  const onAccept = (acceptedByScrolling: boolean) => {
    /* @ts-expect-error */
    typeof getCookieConsentValue() === 'string' && window.adswizzSDK.setListenerConsent(getCookieConsentValue === 'true')
    if (acceptedByScrolling) {
      dispatch(setConsent(false));
      dispatch(setAdswizzConsent(false))
    } else {
      const consentValue = getCookieConsentValue() === "true" ? true : false;
      dispatch(setConsent(consentValue));
      dispatch(setAdswizzConsent(consentValue))

    }
  };

  const onDecline = () => {
     /* @ts-expect-error */
    typeof getCookieConsentValue() === 'string' && window.adswizzSDK.setListenerConsent(getCookieConsentValue === 'true')
    dispatch(setConsent(false));
    dispatch(setAdswizzConsent(false))

  }
  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <CookieConsent
      style={{ position: "sticky", background: "white",  }}
      onAccept={onAccept}
      onDecline={onDecline}
      disableButtonStyles
      data-id="accept cookies"
      buttonText={
        <Button
          variant="solid"
          px={5}
          style={{ background: '#6A5B97', padding: '3px' }}
          color='primary.white'
          _hover={{
            color: 'var(--chakra-colors-green-300)',
            bg: 'primary.main',
          }}
        >
          Accept All
        </Button>
      }
      declineButtonText={
        <Button
          variant="outline"
          color='primary.main'
          borderColor='primary.main'
          style={{ padding: '3px' }}

          _hover={{
            color: "primary.error",
            bg: "white",
          }}
        >
          Decline All
        </Button>
      }
      enableDeclineButton
      buttonStyle={{
        margin: 10,
      }}
    >

        <UnIntrusive handleOpen={handleOpen} />

    </CookieConsent>
  );
};

export default CookieForm;
