
const CreateScope = (action: {
    (): {
      initializeAds: (initialLoading?: boolean, singleRequest?: boolean) => void;
      createSlot: (
        adPath: string,
        elementId: string | undefined,
        // useAmazon: boolean,
        width: number,
        height: number,
        publisherConfig: {
          domain: string
          publisherName: string
          playerName: string
        }
      ) => { display: () => void; refresh: () => void; destroy: () => void };
    };
    (): any;
  }) => action && action();

export default CreateScope