import { useMemo } from "react";
import { ControlsOverlayProps, HlsPlayerProps } from "../components/types";
import usePlayerActions from "./Player/usePlayerActions";

const usePlayer = ({
  audio,
  uiContainerRef,
}: HlsPlayerProps) => {

  const props = usePlayerActions({ audio, uiContainerRef })

  const controls: ControlsOverlayProps = useMemo(
    () => ({ ...props, uiContainerRef, audio }),
    [props]
  );
  return controls;
};
export default usePlayer;
