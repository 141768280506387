import { Button } from '@chakra-ui/button'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/modal'
import { isEmpty } from 'lodash'
import { type ReactNode, type ReactElement } from 'react'

interface SimpleModalProps {
  isOpen: boolean
  close: () => void
  confirm?: () => void
  temp: ReactNode
  confirmText?: string
  closeText?: string
  title?: string | ReactNode
  closeBtn?: boolean
  clickOutside?: boolean
  size?: string
}
const SimpleModal = ({
  isOpen,
  close,
  confirm,
  temp,
  title,
  size = 'md',
  closeText,
  confirmText,
  closeBtn,
  clickOutside = false
}: SimpleModalProps): ReactElement => {
  return (
    <>
      <Modal
        isCentered
        onClose={close}
        isOpen={isOpen}
        closeOnOverlayClick={clickOutside}
        motionPreset="slideInBottom"
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader hidden={isEmpty(title)}>{title}</ModalHeader>
          {closeBtn && <ModalCloseButton />}
          <ModalBody>{temp}</ModalBody>
          <ModalFooter>
            <Button hidden={isEmpty(closeText)} colorScheme="blue" mr={3} onClick={close}>
              {closeText}
            </Button>
            <Button onClick={confirm} hidden={isEmpty(confirmText)} variant="ghost">
              {confirmText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default SimpleModal
