import { Badge, Box, Flex, Link, Text } from "@chakra-ui/react";
import { CgMediaLive } from "react-icons/cg";
import { useSelector } from "react-redux";
import { selectPlaying, selectUrl } from "../../../redux/slices/adsSlice";
import { RiAdvertisementLine } from "react-icons/ri";
import { isEmpty } from "lodash";

const LiveBadge = () => {
  const adPlaying = useSelector(selectPlaying);
  const adClickThroughUrl = useSelector(selectUrl);

  return (
    <Badge
      fontSize={16}
      variant="solid"
      colorScheme={adPlaying ? "blackAlpha" : "red"}
    >
      <Flex align="flex-start" gap={2}>
        {adPlaying ? (
          <Box>
            {isEmpty(adClickThroughUrl) ? (
              <RiAdvertisementLine size={32} />
            ) : (
              <Link href={adClickThroughUrl} isExternal aria-label="ad playing">
                <RiAdvertisementLine size={32} />
              </Link>
            )}
          </Box>
        ) : (
          <Flex gap={2} alignItems="center">
            <Text marginBottom={0}>live</Text>
            <CgMediaLive />
          </Flex>
        )}
      </Flex>
    </Badge>
  );
};

export default LiveBadge;
