import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistReducer, persistStore } from 'redux-persist'
import session from 'redux-persist/lib/storage/session'
import playerReducer from './slices/playerSlice'
import adsReducer from './slices/adsSlice'

const rootReducer = combineReducers({
  player: playerReducer,
  ads: adsReducer,
})
export type RootState = ReturnType<typeof rootReducer>

const persistConfig = {
  key: 'root',
  storage: session,
  whitelist: ['']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)
