import {useDispatch, useSelector} from "react-redux";
import {requestAd} from "./requestAds";
import * as adsState from "../../../redux/slices/adsSlice";
import {
    selectConfig,
    selectPlaying,
    setBuffering,
    setEnded,
    setLoading,
} from "../../../redux/slices/playerSlice";
import {useEffect, useRef} from "react";
import {useGaSendEvent} from "../../GA";
import {config} from "../../../../config";
import {randomUUID} from "node:crypto";

interface ImaSdkProps {
    setStream: (status: boolean) => void;
}

export const useImaSDK = ({
                              setStream,
                          }: ImaSdkProps) => {

    const dispatch = useDispatch();
    const {sendEvent} = useGaSendEvent();
    const adPlaying = useSelector(adsState.selectPlaying);
    const adUrl = useSelector(selectConfig).adUrl;
    const {publisher} = useSelector(selectConfig);

    // console.log({publisherConfig})

    const adDisplayContainerRef = useRef<google.ima.AdDisplayContainer | null>(null);
    const adsLoaderRef = useRef<google.ima.AdsLoader | null>(null);
    const adsManagerRef = useRef<google.ima.AdsManager | null>(null);
    const adsRequestRef = useRef<any>(null);

    const adResolve = useRef<((value?: void | PromiseLike<void>) => void) | null>(null);
    const adReject = useRef<((reason?: any) => void) | null>(null);

    const requestAd = () => {
        dispatch(adsState.setLoading(true));
        adResolve.current = adReject.current = null;

        return new Promise<void>((resolve, reject) => {
            adResolve.current = resolve;
            adReject.current = reject;
            dispatch(adsState.setPrerollePlayed(true));
            if (adDisplayContainerRef.current && adsLoaderRef.current) {
                adsLoaderRef.current.requestAds(adsRequestRef.current!);
            } else {
                dispatch(adsState.setLoading(false));
                reject(new Error("Ad Display Container or Ads Loader not initialized"));
            }
        });
    };

    useEffect(() => {
        if (typeof google === "object" && typeof google.ima === "object") {

            if (adUrl !== "default" && adUrl !== "") {
                dispatch(setLoading(false));

                const videoContent = document.getElementById(config.contentElementId) as HTMLVideoElement;
                const adContainer: HTMLElement | null = document.getElementById("adContainer");

                if (videoContent && adContainer) {
                    google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
                    adDisplayContainerRef.current = new google.ima.AdDisplayContainer(
                        adContainer,
                        videoContent
                    );

                    adsLoaderRef.current = new google.ima.AdsLoader(adDisplayContainerRef.current);

                    adsRequestRef.current = new google.ima.AdsRequest();
                    adsRequestRef.current.adTagUrl = `${adUrl}&cb=${Date.now()}&cust_params=${(publisher?.name?.replace(/\s/g, '') || "unknown")}`;

                    adDisplayContainerRef.current.initialize();


                    const onAdEvent = (adEvent: google.ima.AdEvent) => {

                        const ad = adEvent.getAd();

                        /* @ts-expect-error */
                        switch (adEvent.type) {
                            case google.ima.AdEvent.Type.LOADED:
                                if (!ad!.isLinear() && !!adsManagerRef.current) {

                                }
                                break;

                            case google.ima.AdEvent.Type.STARTED:
                                dispatch(adsState.setPlaying(true));
                                break;

                            case google.ima.AdEvent.Type.AD_BUFFERING:
                                dispatch(setBuffering(true));
                                break;

                            case google.ima.AdEvent.Type.CLICK:
                                sendEvent({
                                    category: "clickthrough_event",
                                    action:
                                        adEvent.getAd()!.getContentType() === "video/mp4"
                                            ? "IMA_video_ad_clickthrough"
                                            : "IMA_audio_ad_clickthrough",
                                    label: "clickthrough",
                                    nonInteraction: false,
                                });
                                dispatch(adsState.setPlaying(false));

                                adsManagerRef.current!.stop();
                                break;

                            case google.ima.AdEvent.Type.AD_PROGRESS:
                                dispatch(setBuffering(false));
                                break;

                            case google.ima.AdEvent.Type.COMPLETE:
                            case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
                            case google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED:
                                if (adResolve.current) adResolve.current();
                                break;
                        }
                    };

                    const onAdsManagerLoaded = (
                        adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent
                    ) => {

                        /* @ts-ignore */
                        const adsRenderingSettings = new google.ima.AdsRenderingSettings();
                        adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

                        dispatch(adsState.setLoading(false));
                        dispatch(adsState.setPrerollePlayed(true));

                        adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(
                            videoContent,
                            adsRenderingSettings
                        );

                        const w = document.getElementById(config.bannerId)?.parentElement?.clientWidth!;
                        const h = document.getElementById(config.bannerId)?.parentElement?.clientHeight!

                        adsManagerRef.current.init(w, h, google.ima.ViewMode.NORMAL);
                        adsManagerRef.current.start();

                        // Add listeners to the required events.
                        adsManagerRef.current!.addEventListener(
                            google.ima.AdErrorEvent.Type.AD_ERROR,
                            (adErrorEvent: google.ima.AdErrorEvent) => {
                                console.error("Ad Error Event:", adErrorEvent);
                                sendEvent({
                                    category: "error_event",
                                    action: "ad_error",
                                    label: "Ad Error",
                                    nonInteraction: true,
                                });
                                dispatch(adsState.setError("error occured in the ads player"));
                                if (adsManagerRef.current)
                                    adsManagerRef.current.destroy();

                                if (adReject.current)
                                    adReject.current()
                            }
                        );

                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.LOADED,
                            onAdEvent
                        );
                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.STARTED,
                            onAdEvent
                        );
                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.CLICK,
                            onAdEvent
                        );
                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.INTERACTION,
                            onAdEvent
                        );
                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.AD_BUFFERING,
                            onAdEvent
                        );
                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.AD_PROGRESS,
                            onAdEvent
                        );

                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
                            onAdEvent
                        );
                        adsManagerRef.current!.addEventListener(
                            google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
                            onAdEvent
                        );
                    };

                    adsLoaderRef.current!.addEventListener(
                        /* @ts-ignore */
                        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                        onAdsManagerLoaded,
                        false
                    );

                    adsLoaderRef.current!.addEventListener(
                        /* @ts-ignore */
                        google.ima.AdErrorEvent.Type.AD_ERROR,
                        (adErrorEvent: google.ima.AdErrorEvent) => {
                            console.error("Ad Error Event:", adErrorEvent);
                            dispatch(adsState.setLoading(false));

                            sendEvent({
                                category: "error_event",
                                action: "ad_error",
                                label: "Ad Error",
                                nonInteraction: true,
                            });

                            if (adsManagerRef.current)
                                adsManagerRef.current.destroy();

                            if (adReject.current)
                                adReject.current()
                        },
                        false
                    );

                    const contentEndedListener = function () {
                        if (adPlaying) return;
                        dispatch(setEnded(true));
                        adsLoaderRef.current!.contentComplete();
                    };

                    videoContent.onended = contentEndedListener;

                    dispatch(adsState.setLoading(false));
                }
            }
            else {

                dispatch(adsState.setLoading(false));
            }
        }
    }, [adUrl, publisher]);


    return {
        requestAd,
    };
};
