import { AdsProps } from "../../../../components/types";

const DestroySlot = ({ slot }: AdsProps) => {
  if (slot) {
    const tmp = slot;
    googletag.cmd.push(() => {
      googletag.destroySlots([tmp]);
    });
    slot = null;
  }
};

export default DestroySlot;
