export const decorateUrl = (url: string) => {

     if (!url)
          return url;

     let streamUrl = `${url}&test=true`;
     console.log({"streamUrl": streamUrl})

     /* @ts-ignore */
     streamUrl = window.adswizzSDK?.decorateURL(streamUrl);
     console.log({"decoratedStreamUrl": streamUrl})

     return streamUrl;
}