import {
  Flex,
  Box,
  SliderTrack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  IconButton,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { RxSpeakerOff } from "react-icons/rx";
import { useSelector } from "react-redux";
import { usePlayerContext } from "../../../providers/PlayerProvider.tsx";
import {
  selectConfig,
  selectConfigError,
  selectVolume,
} from "../../../redux/slices/playerSlice";
import { selectPlaying } from "../../../redux/slices/adsSlice";

import ButtonIcon from "../../ButtonIcon";
import { debounce } from "lodash";
const Volume = () => {
  const { speakerIcon, mute, changeVolume } = usePlayerContext();

  const volume = useSelector(selectVolume);
  const volumeRef = useRef<any>();
  const adsPlaying = useSelector(selectPlaying);
  const config = useSelector(selectConfig);
  const configError = useSelector(selectConfigError);
  return (
    <Flex alignItems="center" flexDirection="column" ref={volumeRef}>
      <Popover trigger="hover" placement="top-start">
        <PopoverTrigger>
          <IconButton
            color={config.colors.secondary}
            _hover={{
              color: config.colors.highlight,
            }}
            data-id="volume-mute-or-unmute"
            variant="ghost"
            aria-label="Volume"
            isDisabled={adsPlaying || configError.error || config.loading}
            icon={volume === 0 ? <RxSpeakerOff size={24} /> : speakerIcon}
          />
        </PopoverTrigger>
        <PopoverContent
          backgroundColor="rgba(0,0,0,0.4)"
          border="none"
          width="40px"
        >
          <PopoverBody>
            <Slider
              aria-label="Volume slider"
              data-id="volume-slider"
              colorScheme="purple"
              value={volume}
              onChange={changeVolume}
              min={0}
              max={1}
              step={0.01}
              orientation="vertical"
              minH="16"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default Volume;
