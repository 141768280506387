import { AdsProps } from "../../../../components/types";

declare global {
  interface Window {
    googletag: any;
    apstag: any;
  }
}

const AmazonPS = (
  adPath: string,
  adWidth: number,
  adHeight: number,
  elementId: string | undefined,
  slot: googletag.Slot
  // slot: AdsProps
) => {
  window.apstag.fetchBids({
      slots: [{
        slotID: elementId,
        slotName: adPath,
        sizes: [adWidth, adHeight]
      }],
      timeout: 2e3
    }, function () {
      window.googletag.cmd.push(function () {
        window.apstag.setDisplayBids();
        window.googletag.pubads().refresh([slot]);
      });
    });
};

export default AmazonPS;
