import { Dispatch } from "@reduxjs/toolkit";
import Hls from "hls.js";
import { HlsPlayerProps } from ".";
import { SendEventProps } from "../../components/types";
import {setBuffering, setMetadata, setStreamInfo} from "../../redux/slices/playerSlice";
import formatText from "../../util/formatText";
import { playContent } from "../../util/playContent";

interface InitHlsPlayerProps extends HlsPlayerProps {
    dispatch: Dispatch
    sendEvent: (props: SendEventProps) => void
    streamUrl: string
    metadata: { name: string, title: string }
}
let hls: Hls;

const _initHlsPlayer = ({
    hlsConfig,
    playerRef,
    src,
    autoPlay,
    dispatch,
    sendEvent,
    streamUrl,
    metadata,
  }: InitHlsPlayerProps) => {
    if (hls != null) {
      hls.destroy();
    }
    const newHls = new Hls({
      enableWorker: false,
      ...hlsConfig,
    });


    if (playerRef?.current) {

      newHls.attachMedia(playerRef.current);
    }

    newHls.on(Hls.Events.FRAG_PARSING_METADATA, function (event, data) {
      if (data) {
        if (data.frag.title) {
          const splitData = data.frag.title?.split("-");
          const dataTitle = formatText(splitData?.[1]);
          const dataName = formatText(splitData?.[0]);
          const { title, name } = metadata;
          if (title !== dataTitle || name !== dataName) {
            dispatch(setMetadata({ title: dataTitle, name: dataName }));
          }
        }
      }
    });

    newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
      newHls.loadSource(streamUrl);

      newHls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        const { levels } = data;
        const isVideo = levels.some(level => level.videoCodec);
        const isAudio = levels.some(level => level.audioCodec);

        console.log({isVideo, isAudio})

        dispatch(setStreamInfo({ isVideo, isAudio }))
        if (autoPlay && playerRef) {
          playContent({ playerRef, dispatch })
        }
      });
    });

    newHls.on(Hls.Events.ERROR, function (event, data) {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            sendEvent({
              category: "error_event",
              action: "network_error",
              label: "Network Error",
              value: data.networkDetails,
              nonInteraction: true,
            });

            newHls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            sendEvent({
              category: "error_event",
              action: "media_error",
              label: "media type error",
              value: data.networkDetails,
              nonInteraction: true,
            });
            if (src && playerRef?.current) {
              playerRef.current.src = src;
             playContent({ playerRef, dispatch })
            }

            newHls.recoverMediaError();
            break;
          default:
            hls.destroy()
            break;
        }
      }
    });

    hls = newHls;
  }
export default _initHlsPlayer;