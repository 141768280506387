import { useSelector } from "react-redux";
import { selectConsent } from "../../../redux/slices/adsSlice";
import { InitializeAds, CreateScope, CreateSlot } from "./Utils";

const useGpt = () => {
  window.apstag = window.apstag || {
    init: function () {},
    fetchBids: function () {},
    setDisplayBids: function () {},
  };
  window.apstag.init({
    pubID: "",
    adServer: "googletag",
  });
  window.googletag = window.googletag || { cmd: [] };

  const consentValue = useSelector(selectConsent);

  googletag.cmd.push(() => {
    const pubads = googletag.pubads()
        .setPrivacySettings({
          restrictDataProcessing: !consentValue,
          nonPersonalizedAds: !consentValue,
        });

    const GPTAdsManager: any = CreateScope(() => {
      const initializeAds = (
          initialLoading = false, singleRequest = true
      ) =>
          InitializeAds(initialLoading, singleRequest, pubads);

      const createSlot = (
          adPath: string,
          elementId: string | undefined,
          width: number,
          height: number,
          publisherConfig: {
            domain: string
            publisherName: string
            playerName: string
          }
      ) =>
          CreateSlot(
              adPath, elementId, consentValue, false,
              pubads, width, height, publisherConfig
          )

      return { initializeAds, createSlot };

    });

    return GPTAdsManager;

  });
};
export default useGpt;
