import { BsPauseCircle, BsPlayCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { usePlayerContext } from "../../../providers/PlayerProvider.tsx";
import {
  selectLoading,
  selectPlaying,
} from "../../../redux/slices/playerSlice";
import ButtonIcon from "../../ButtonIcon";
import { ControlsOverlayProps } from "../../types";

const PlayButton = () => {
  const { play }: ControlsOverlayProps = usePlayerContext();
  const playing = useSelector(selectPlaying);
  const loading = useSelector(selectLoading);

  return (
    <ButtonIcon
      id="atunwa-player-play-button"
      dataId="play-radio-button"
      aria={playing ? "Pause" : "Play"}
      icon={playing ? <BsPauseCircle size={40} /> : <BsPlayCircle size={40} />}
      loading={loading}
      onClick={play}
    />
  );
};
export default PlayButton;
