import dashjs from "dashjs";
import Hls, { HlsConfig } from "hls.js";
import React, { RefObject, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMetadata, setBuffering } from "../../redux/slices/playerSlice";
import { get_url_extension, handleTextMetadata } from "../../util/formatText";
import { playContent } from "../../util/playContent";
import { decorateUrl } from "../Ads/Adswizz/decorateUrl";
import { useGaSendEvent } from "../GA";
import _initHlsPlayer from "./initHlsPlayer";
import { config } from "../../../config";
import * as adsState from "../../redux/slices/adsSlice";


export interface HlsPlayerProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  hlsConfig?: HlsConfig;
  playerRef?: RefObject<HTMLVideoElement>;
  src: string;
}

function ReactHlsPlayer({
  hlsConfig,
  playerRef = React.createRef<HTMLVideoElement>(),
  src,
  autoPlay,
  ...props
}: HlsPlayerProps) {
  const dispatch = useDispatch();
  const metadata = useSelector(selectMetadata);
  const { sendEvent } = useGaSendEvent();
  const prerolledPlayed = useSelector(adsState.selectPrerollPlayed);


  useEffect(() => {
    const streamUrl = decorateUrl(src);

    if (get_url_extension(streamUrl) === "mpd") {
      if (playerRef.current) {
        playerRef.current.useCredential = true;
        // var player = dashjs.MediaPlayer().create();
        // player.initialize(playerRef.current, streamUrl, false);

        if (src && prerolledPlayed) {
          playContent({ dispatch, playerRef });
        }
      }
    } else if (get_url_extension(streamUrl) === "m3u8") {
      let hls: Hls;

      if (Hls.isSupported()) {
        _initHlsPlayer({
          hlsConfig,
          playerRef,
          src,
          autoPlay,
          dispatch,
          sendEvent,
          streamUrl,
          metadata,
        });

        if (src && prerolledPlayed) playContent({ playerRef, dispatch });
      } else if (
        playerRef.current?.canPlayType("application/vnd.apple.mpegurl")
      ) {
        if (src && prerolledPlayed) {
          playerRef.current.src = src;
          playContent({ playerRef, dispatch });
        }
      }

      playerRef.current?.addEventListener("loadedmetadata", (event: any) =>
        handleTextMetadata({ event, metadata, dispatch })
      );

      playerRef.current?.addEventListener("waiting", () => {
        sendEvent({
          category: "buffering_event",
          action: "buffering",
          label: "Player Buffering",
          nonInteraction: true,
        });
        dispatch(setBuffering(true));
      });

      playerRef.current?.addEventListener("playing", () => {
        dispatch(setBuffering(false));
      });

      return () => {
        playerRef.current?.removeEventListener("waiting", () => {
          dispatch(setBuffering(true));
        });
        playerRef.current?.removeEventListener("playing", () => {
          dispatch(setBuffering(false));
        });

        if (hls != null) {
          hls.destroy();
        } else if (get_url_extension(streamUrl) === "mp3") {
          if (src && playerRef?.current && prerolledPlayed) {
            playerRef.current.src = streamUrl;
            playContent({ playerRef, dispatch });
          }
        } else {
          if (src && playerRef?.current && prerolledPlayed) {
            playerRef.current.src = streamUrl;
            playContent({ playerRef, dispatch });
          }
        }
      };
    } else {
      if (src && playerRef?.current && prerolledPlayed) {

        playerRef.current.src = streamUrl;
        playContent({ playerRef, dispatch });
      }
    }
  }, [autoPlay, src, prerolledPlayed]);

  return (
      <div>
        <video
          ref={playerRef}
          {...props}
          controls={false}
          id={config.contentElementId}
          playsInline
        />
      </div>
  );
}

export default ReactHlsPlayer;
