import { Box, Flex, Stack, useDisclosure } from "@chakra-ui/react";
import { CgArrowsExpandLeft } from "react-icons/cg";
import { MdCloseFullscreen } from "react-icons/md";
import { HiOutlineShare } from "react-icons/hi";
import { useSelector } from "react-redux";
import { usePlayerContext } from "../../../../providers/PlayerProvider.tsx";
import { selectIsFullscreen } from "../../../../redux/slices/playerSlice";
import ButtonIcon from "../../../ButtonIcon";
import Volume from "../../Volume";
import SimpleModal from "../../../Modal";
import SocialShare from "../../../SocialShare";
import PoweredByLogo from "../../../../assets/icons/PoweredByLogo";

const SHOW_FULL_SCREEN = false;

const Controls = () => {
  const { fullScreen, isSmallScreen } = usePlayerContext();
  const isFullscreen = useSelector(selectIsFullscreen);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex gap={1} justifyContent="flex-end" alignItems="center">
      <Volume />

      <Box hidden={isSmallScreen}>
        {SHOW_FULL_SCREEN && (
          <ButtonIcon
            dataId="full-screen"
            data-testid="fullscreen"
            aria="Full screen "
            onClick={fullScreen}
            icon={
              isFullscreen ? (
                <MdCloseFullscreen size={30} />
              ) : (
                <CgArrowsExpandLeft size={30} />
              )
            }
          />
        )}
      </Box>
      <ButtonIcon
        dataId="share-button"
        onClick={onOpen}
        icon={<HiOutlineShare size={24} />}
        aria="Share"
      />
      <SimpleModal
        isOpen={isOpen}
        clickOutside={true}
        close={onClose}
        temp={<SocialShare />}
      />
    </Flex>
  );
};

export default Controls;
