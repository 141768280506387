import { useEffect, useState } from "react";
import loadScript from "../util/loadScript";
import AtunwaApp from "./AtunwaApp";
import { Box, Flex, Text, Stack, Spinner } from "@chakra-ui/react";
import { IoLogoJavascript } from "react-icons/io";
import { selectAdblockerEnabled } from "../redux/slices/playerSlice";
import { useSelector } from "react-redux";
import AtunwaAppAdblocker from "./AtunwaAppAdblocker";

const AtunwaPlayerBundle = () => {
  const [isGoogle, setIsGoogle] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("Loading Player...");
  const adblockerEnabled = useSelector(selectAdblockerEnabled);

  useEffect(() => {
    const loadScripts = async () => {
      const scriptUrls = [];
      if (typeof google === "undefined")
        scriptUrls.push("https://imasdk.googleapis.com/js/sdkloader/ima3.js");
      else if (typeof google.ima === "undefined")
        scriptUrls.push("https://imasdk.googleapis.com/js/sdkloader/ima3.js");
      if (typeof googletag === "undefined")
        scriptUrls.push("https://securepubads.g.doubleclick.net/tag/js/gpt.js");
      else if (typeof googletag.pubads === "undefined")
        scriptUrls.push("https://securepubads.g.doubleclick.net/tag/js/gpt.js");
      if (typeof window.apstag === "undefined")
        scriptUrls.push("//c.amazon-adsystem.com/aax2/apstag.js");
      /* @ts-expect-error */
      if (typeof window.adswizzSDK === "undefined")
        scriptUrls.push("https://amp.atunwadigital.com/adswizzsdk.v7.9.2.min.js");

      const scriptPromises = scriptUrls?.map((scriptUrl) =>
        loadScript(scriptUrl)
      );

      try {
        await Promise.all(scriptPromises);
        setIsGoogle(true);
      } catch (error) {
        setMsg("Error loading scripts");
      }
    };
    loadScripts();
  }, []);

  if ((isGoogle && typeof google === "object") ) {
    return <AtunwaApp />;
  } else if (adblockerEnabled){
    return <AtunwaAppAdblocker />
  }else {
    return (
      <Box h={400} bg="gradient.purple">
        <Flex justifyContent="center" align="center" h="full">
          <Stack gap={5}>
            <Flex
              style={{ transition: "all .3s linear" }}
              justifyContent="center"
              color={
                msg.includes("Error loading")
                  ? "primary.error"
                  : "primary.white"
              }
            >
              {msg.includes("Error loading") ? (
                <IoLogoJavascript size={35} />
              ) : (
                <Spinner size="xl" />
              )}
            </Flex>
            <Text color="primary.white">{msg}</Text>
          </Stack>
        </Flex>
      </Box>
    );
  }
};

export default AtunwaPlayerBundle;
