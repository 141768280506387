import { IconButton, Tooltip } from "@chakra-ui/react";
import { JSXElementConstructor, ReactElement, RefObject } from "react";
import { useSelector } from "react-redux";
import { selectPlaying } from "../../redux/slices/adsSlice";
import * as adsState from "../../redux/slices/adsSlice";
import {
  selectConfig,
  selectConfigError,
} from "../../redux/slices/playerSlice";

interface IconButtonProps {
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  aria: string;
  dataId?: string;
  id?: string
  loading?: boolean;
  onClick?: (e?: any) => void | any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
const ButtonIcon = ({
  icon,
  aria,
  loading,
  onClick,
  dataId,
  id,
  onMouseEnter,
  onMouseLeave,
}: IconButtonProps) => {
  const adsPlaying = useSelector(selectPlaying);
  const adsLoading = useSelector(adsState.selectLoading);
  const isLoading = aria === "Play" ? loading || adsLoading : loading;
  const publisherConfig = useSelector(selectConfig);
  const configError = useSelector(selectConfigError);

  return (
    <Tooltip
      hasArrow
      bg="primary.main"
      color='white'
      placement="top"
      label={aria}
    >
      <IconButton
        color={publisherConfig.colors.secondary}
        isLoading={isLoading}
        _hover={{
          color: publisherConfig.colors.highlight,
        }}
        aria-label={aria}
        isRound
        onClick={onClick}
        variant="ghost"
        colorScheme="purple"
        isDisabled={adsPlaying || configError.error || publisherConfig.loading}
        icon={icon}
        data-id={dataId}
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </Tooltip>
  );
};

export default ButtonIcon;
